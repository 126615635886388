import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../configs";
import { useTranslation } from "react-i18next";
import Navbar from "./Navbar";
import Footer from "./Footer";

import "../assets/css/style.min.css";
import "../assets/css/icons.min.css";
import "../assets/libs/aos/aos.css";

const Login = () => {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const nextUrl = queryParam.get("next");
  const language = localStorage.getItem("language") || "en";

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage(null);

    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);

    try {
      const response = await axios.post(`${API_URL}/login`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      localStorage.setItem("token", response.data.access_token);
      localStorage.setItem("username", username);
      navigate(nextUrl || "/");
    } catch (error) {
      if (error.response?.data?.detail) {
        setErrorMessage(t(error.response.data.detail));
      } else {
        setErrorMessage(t("Login failed. Please try again."));
      }
    }
  };

  return (
    <div style={styles.pageContainer}>
      <Navbar />
      <div style={styles.loginContainer}>
        <h2 style={styles.heading}>{t("Login")}</h2>
        {errorMessage && <div style={styles.errorAlert}>{errorMessage}</div>}
        <form onSubmit={handleLogin} style={styles.form}>
          <input
            type="text"
            dir={language === "ar" ? "rtl" : "ltr"}
            placeholder={t("Username")}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={styles.input}
            required
          />
          <input
            type="password"
            dir={language === "ar" ? "rtl" : "ltr"}
            placeholder={t("Password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={styles.input}
            required
          />
          <button type="submit" style={styles.button}>
            {t("Login")}
          </button>
        </form>
        <p style={styles.text} dir={language == 'ar'? 'rtl' : 'ltr'}>
          {t("Don't have an account?")}{" "}
          <a href="/signup" style={styles.link}>
            {t("Sign up")}
          </a>
        </p>
      </div>
    </div>
  );
};

const styles = {
  pageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#1a202c", // Dark background color for a professional look
  },
  loginContainer: {
    width: "100%",
    maxWidth: "400px",
    padding: "2rem",
    backgroundColor: "#2d3748",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  heading: {
    marginBottom: "1.5rem",
    fontSize: "1.5rem",
    color: "#e2e8f0", // Light gray text color
  },
  errorAlert: {
    backgroundColor: "#e53e3e", // Red background for error
    color: "#fff",
    padding: "0.75rem",
    borderRadius: "4px",
    marginBottom: "1rem",
    fontSize: "0.875rem",
    textAlign: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    padding: "0.75rem",
    marginBottom: "1rem",
    borderRadius: "4px",
    border: "1px solid #4a5568",
    backgroundColor: "#2d3748",
    color: "#e2e8f0",
    fontSize: "1rem",
  },
  button: {
    padding: "0.75rem",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#7C3AED",
    color: "#fff",
    fontSize: "1rem",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  text: {
    color: "#e2e8f0",
    marginTop: "1rem"
  },
  link: {
    color: "#7C3AED",
    textDecoration: "none",
    fontWeight: "bold",
  },
};

export default Login;
