import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BetaNotice from "./BetaNotice";

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const username = localStorage.getItem("username");
    const [language, setLanguage] = useState(localStorage.getItem("language") || window.navigator.language.split("-")[0] || "ar");
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleLogin = () => {
        window.location.href = "/login";
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        window.location.reload();
    };

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
        localStorage.setItem("language", lang);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1024);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <div>
                {/* Background elements */}
                <div className="fixed top-0 -z-10">
                    <div className="blur-[200px] w-[500px] h-[500px] rounded-full bg-gradient-to-tl from-red-600/40 to-pink-600/40"></div>
                </div>
                <div className="fixed bottom-0 start-0 -z-10">
                    <div className="blur-[200px] w-[500px] h-[500px] rounded-full bg-gradient-to-tl from-red-600/40 to-pink-600/40"></div>
                </div>
            </div>
            <header id="navbar-sticky" className="navbar">
                <BetaNotice />
                <div className="container">
                    <nav>
                        <a href="/" className="logo">
                            <h1 className="text-2xl font-medium text-white">RAGMinds</h1>
                        </a>

                        {/* Mobile Menu Toggle */}
                        <div className="lg:hidden flex items-center ms-auto px-2.5">
                            <button
                                className="hs-collapse-toggle inline-flex items-center justify-center h-9 w-12 rounded-md border border-white/20 bg-default-100/5"
                                type="button"
                                onClick={toggleMenu}
                            >
                                <i data-lucide="menu" className="h-5 w-5 stroke-white"></i>
                            </button>
                        </div>

                        {/* Menu */}
                        <div
                            id="mobileMenu"
                            className={`hs-collapse transition-all duration-300 lg:basis-auto basis-full grow ${
                                isMenuOpen ? "flex" : "hidden"
                            } lg:flex items-center justify-center mx-auto mt-2 lg:mt-0`}
                        >
                            <ul
                                id="navbar-navlist"
                                className="navbar-nav"
                                style={{
                                    direction: language === "ar" ? "rtl" : "ltr",
                                }}
                            >
                                <li className="nav-item">
                                    <a className="nav-link" href="/#home">
                                        {t("Home")}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/#tools">
                                        {t("Tools")}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/#features">
                                        {t("Features")}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/#contact">
                                        {t("Contact")}
                                    </a>
                                </li>
                                {isMobile && (
                                    <>
                                        <li className="nav-item">
                                            {username ? (
                                                <button
                                                    onClick={handleLogout}
                                                    className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary-hover transition duration-300"
                                                >
                                                    {t("Logout")}
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={handleLogin}
                                                    className="px-4 py-2 text-md font-medium text-white bg-primary rounded-lg hover:bg-primary-hover transition duration-300"
                                                >
                                                    {t("Login")}
                                                </button>
                                            )}
                                        </li>
                                        <li className="nav-item">
                                            <select
                                                onChange={(e) => changeLanguage(e.target.value)}
                                                className="bg-gray-700 px-3 py-2 rounded-lg text-white"
                                                value={language}
                                                style={{
                                                    backgroundColor: "#7C3AED",
                                                    borderRadius: 8,
                                                    width: "100%",
                                                }}
                                            >
                                                <option value="ar">{t("Arabic")}</option>
                                                <option value="en">{t("English")}</option>
                                                <option value="tr">{t("Turkish")}</option>
                                            </select>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>

                        {/* Desktop User and Language Section */}
                        {!isMobile && (
                            <div className="ms-auto flex items-center space-x-4">
                                {username ? (
                                    <div className="flex items-center space-x-2" style={{marginRight: 15}}>
                                        <p style={{ marginRight: 10 }} className="text-white">
                                            {t("Welcome")}, {username || t("Guest")}!
                                        </p>
                                        <button
                                            onClick={handleLogout}
                                            className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary-hover transition duration-300"
                                        >
                                            {t("Logout")}
                                        </button>
                                    </div>
                                ) : (
                                    <button style={{marginRight: 15}}
                                        onClick={handleLogin}
                                        className="px-4 py-2 text-md font-medium text-white bg-primary rounded-lg hover:bg-primary-hover transition duration-300"
                                    >
                                        {t("Login")}
                                    </button>
                                )}
                                <select
                                    onChange={(e) => changeLanguage(e.target.value)}
                                    className="bg-gray-700 px-3 py-2 rounded-lg text-white"
                                    value={language}
                                    style={{
                                        backgroundColor: "#7C3AED",
                                        borderRadius: 8,
                                        width: 100,
                                    }}
                                >
                                    <option value="ar">{t("Arabic")}</option>
                                    <option value="en">{t("English")}</option>
                                    <option value="tr">{t("Turkish")}</option>
                                </select>
                            </div>
                        )}
                    </nav>
                </div>
            </header>
        </>
    );
}

export default Navbar;
