import { useTranslation } from "react-i18next";

const BetaNotice = () => {
    const { t } = useTranslation();
    const language = localStorage.getItem("language") || "ar";
    return (
        <div dir={language == 'ar' ? 'rtl' : 'ltr'} style={{
        backgroundColor: '#fffae6',
        padding: '10px',
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: 'bold',
        borderBottom: '2px solid #ffcc00'
        }}>
        🌟 {t("Beta Version Notice: This is the beta version of RAGMinds. The full production release with enhanced features is coming soon! Stay tuned.")} 🚀
        </div>
  );
}
  
  export default BetaNotice;