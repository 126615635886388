import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Header from "../components/Header";
import api from "../utils/api";
import { MEDIA_URL, API_URL } from "../configs";
import axios from "axios";
import { useTranslation } from "react-i18next";

import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import { useNavigate, useLocation } from "react-router-dom";

import "../assets/css/style.min.css";
import "../assets/css/icons.min.css";
import "../assets/libs/aos/aos.css";
import Navbar from "../components/Navbar";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

const ChatPDF = () => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [userMessage, setUserMessage] = useState("");
  const [botTyping, setBotTyping] = useState(false); // New state for bot typing
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const language = localStorage.getItem("language") || "en";

  const queryParam = new URLSearchParams(location.search);
  const documentId = queryParam.get("document_id");

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  const handlePreviousPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const handleNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login?next=/chatpdf");
    }
    if (documentId) {
      const pdfUrl = `${MEDIA_URL}/${documentId}.pdf`;
      setPdfUrl(pdfUrl);
    }
  }, [navigate]);

  const handlePDFUpload = async (event) => {
    event.preventDefault();
    setProcessing(true);
    if (!pdfFile) return;
    const formData = new FormData();
    formData.append("pdfFile", pdfFile);

    try {
      const token = localStorage.getItem("token");
      // const response = await axios.post("https://api.ragminds.com/upload-pdf/", formData, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });
      const response = await fetch(`${API_URL}/upload-pdf/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      
      const data = await response.json();
      if (response.ok) {
        setPdfUrl(URL.createObjectURL(pdfFile));
        setProcessing(false);
        navigate(`?document_id=${data.document_id}`);
      } else {
        setProcessing(false);
        const errMsg = data.detail || t("Failed to upload PDF. Please try again.");
        alert(t("Error: ") + errMsg);
      }
    } catch (error) {
      setProcessing(false);
      alert(error.message || t("Failed to upload PDF. Please try again."));
      console.error(t("Error uploading PDF:"), error);
    }
  };

  const handleSendMessage = async () => {
    if (!userMessage) return;

    setChatMessages((prev) => [...prev, { text: userMessage, isUser: true }]);
    setUserMessage("");
    setBotTyping(true); // Start bot typing

    const formData = new FormData();
    formData.append("question", userMessage);
    formData.append("document_id", documentId);

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(`${API_URL}/ask-question/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setChatMessages((prev) => [
        ...prev,
        { text: response.data.answer.answer, isUser: false },
      ]);
    } catch (error) {
      console.error(t("Error sending message:"), error);
    } finally {
      setBotTyping(false); // Stop bot typing
    }
  };

  return (
    <div className="bg-default-900">
      {/* Header */}
      <Navbar />

      {/* Main Content */}
      <section style={{marginTop: "100px"}} className="py-20">
        <div className="container text-center">
          <h1 className="text-4xl font-medium text-white mb-6">
            {t("Chat with Your PDF")}
          </h1>

          {/* PDF Upload Form */}
          {!pdfUrl && (
            <>
          <p dir={language == 'ar' ? 'rtl': 'ltr'} className="text-lg text-default-300 mb-10">
            {t("Upload a PDF and interact with its content using our AI-powered chatbot.")}
          </p>
          <div  dir={language == 'ar' ? 'rtl' : 'ltr'} className="max-w-xl mx-auto bg-default-950/40 p-8 rounded-lg backdrop-blur-3xl mb-8">
            <label className="text-lg text-white mb-2 block">{t("Upload Your PDF")}</label>
            <input
              type="file"
              accept="application/pdf"
              onChange={(e) => setPdfFile(e.target.files[0])}
              className="block w-full p-3 rounded-lg bg-default-700 text-white border border-default-400 mb-4"
              required
              disabled={processing}
            />
            <button className="bg-primary text-white py-2 px-6 rounded-full hover:bg-primary-hover transition-all duration-300" onClick={handlePDFUpload}>
              {processing ? t("Processing...") : t("Upload PDF")}
            </button>
          </div>
          </>
          )}

          {/* PDF Preview and Chat Section */}
          {pdfUrl && (
            <>
            <div className="p-4 rounded-lg mb-8">
              <button
                className="bg-primary text-white py-2 px-6 rounded-full hover:bg-primary-hover transition-all duration-300"
                onClick={() => {
                  window.location.replace("/chatpdf");
                }}
              >
                {t("Start New Conversation")}
              </button>
            </div>
            <div className="flex gap-6">
              {/* PDF Viewer */}
              <div className="bg-default-950/40 p-4 rounded-lg backdrop-blur-3xl" style={{ flexBasis: "50%" }}>
                <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={pageNumber} width={500} />
                </Document>
                <div className="flex justify-between mt-4">
                  <button
                    onClick={handlePreviousPage}
                    disabled={pageNumber <= 1}
                    className="bg-primary text-white py-2 px-4 rounded-lg disabled:opacity-50 hover:bg-primary-hover"
                  >
                    Previous
                  </button>
                  <p className="text-white">
                    {t("Page")} {pageNumber} {t("of")} {numPages}
                  </p>
                  <button
                    onClick={handleNextPage}
                    disabled={pageNumber >= numPages}
                    className="bg-primary text-white py-2 px-4 rounded-lg disabled:opacity-50 hover:bg-primary-hover"
                  >
                    {t("Next")}
                  </button>
                </div>
              </div>

              {/* Chat Interface */}
              <div className="bg-default-950/40 p-4 rounded-lg backdrop-blur-3xl" style={{ flexBasis: "50%" }}>
                <div className="bg-default-800 p-4 rounded-lg h-[400px] overflow-y-auto mb-4 text-default-200">
                  {chatMessages.map((msg, index) => (
                    <div key={index} className={msg.isUser ? "text-right mb-2" : "text-left mb-2"}>
                      <strong>{msg.isUser ? t("You") : t("Me(pdf)")}:</strong> {msg.text}
                    </div>
                  ))}
                  {botTyping && (
                    <div className="text-left mb-2">
                      <strong>{t("Me(pdf):")}</strong> <i>{t("Typing...")}</i>
                    </div>
                  )}
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    value={userMessage}
                    onChange={(e) => setUserMessage(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSendMessage();
                      }
                    }}
                    className="flex-1 p-3 rounded-lg bg-default-700 text-white border border-default-400"
                    placeholder={t("Type your message here...")}
                  />
                  <button
                    onClick={handleSendMessage}
                    className="bg-primary text-white py-2 px-6 rounded-full hover:bg-primary-hover transition-all duration-300"
                    disabled={botTyping}
                  >
                    {botTyping ? "..." : t("Send")}
                  </button>
                </div>
              </div>
            </div>
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default ChatPDF;
