import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { API_URL } from "../configs";
import { useTranslation } from "react-i18next";

import "../assets/css/style.min.css";
import "../assets/css/icons.min.css";
import "../assets/libs/aos/aos.css";

const RagChatmind = () => {
  const [chatConfig, setChatConfig] = useState({
    "width": "400",
    "height": "500",
    "bgColor": "#ffffff",
    "headerBgColor": "#0084ff",
    "headerTextColor": "#ffffff",
    "userMsgBgColor": "#0084ff",
    "userMsgTextColor": "#ffffff",
    "botMsgBgColor": "#f1f0f0",
    "botMsgTextColor": "#000000",
});

  const navigate = useNavigate();
  const location = window.location;
  const queryParam = new URLSearchParams(location.search);
  const chatId = queryParam.get("chat_id");
  const language = localStorage.getItem("language") || "en";

  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem("messages");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });
  const [userMessage, setUserMessage] = useState("");
  const [isChatOpen, setIsChatOpen] = useState(true);
  const [isSDKOpen, setIsSDKOpen] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [excelFile, setExcelFile] = useState(null);
  const [showUpload, setShowUpload] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [chatbotSecret, setChatbotSecret] = useState(null);
  const { t } = useTranslation();

  const getChatDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/chatbot/${chatId}`, {headers: {Authorization: `Bearer ${token}`}});
      if (response.status === 200) {
        setChatConfig(response.data.chatbot_config);
        setChatbotSecret(response.data.secret_key);
        setShowUpload(false);
      } else {
        console.error("Failed to fetch chatbot details:", response);
        alert(t("Failed to fetch chatbot details. Please try again."));
      }
    } catch (error) {
      console.error("Error fetching chatbot details:", error);
      alert(t("Failed to fetch chatbot details. Please try again."));
    }
  };

  useEffect(() => {
    // Save messages to localStorage whenever they change
    localStorage.setItem("messages", JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login?next=/chatbot");
    }

    if (chatId) {
      getChatDetails();
    }
  }, [chatId]);

  const updateConfig = (key, value) => {
    setChatConfig({ ...chatConfig, [key]: value });
  };

  const sendMessage = async () => {
    if (!userMessage) return;

    setSendingMessage(true);

    const userMsg = { content: userMessage, type: "user" };
    setMessages((prevMessages) => [...prevMessages, userMsg]);

    const formData = new FormData();
    formData.append("question", userMessage);
    formData.append("document_id", chatId);

    try {
      const response = await axios.post(`${API_URL}/ask-question-rag/`, formData, 
        {headers: {"Client-Secret": chatbotSecret}
      });
      const answer = response.data.answer;
      const botResponse = { content: answer, type: "bot" };
      setMessages((prevMessages) => [...prevMessages, botResponse]);
      setUserMessage(""); // Clear input
      setSendingMessage(false);
    } catch (error) {
      console.error("Error sending message:", error);
      const botResponse = { content: t("An error occurred. Please try again."), type: "bot" };
      setMessages((prevMessages) => [...prevMessages, botResponse]);
    } finally {
      setSendingMessage(false);
    }
    
    // Mock bot response
    // const botResponse = { content: `Echo: ${userMessage}`, type: "bot" };
    // setMessages((prevMessages) => [...prevMessages, botResponse]);

    // setUserMessage(""); // Clear input
  };

  const handleFileUpload = async () => {
    if (excelFile) {
      setProcessing(true);
      // Upload file to server
      const formData = new FormData();
      formData.append("excelFile", excelFile);

      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(`${API_URL}/upload-excel/`, formData, {headers: {Authorization: `Bearer ${token}`}});
        if (response.status === 200) {
          // Parse the response and set the product information
          const { data } = response;
          console.log(data);
          setFileUploaded(true);
          navigate(`?chat_id=${data.document_id}`);
        } else {
          console.error("Failed to upload file:", response);
          alert(response.data.detail || t("Failed to upload file. Please try again."));
        }
      }
      catch (error) {
        console.error("Error uploading file:", error);
        alert(error.response.data.detail || t("Failed to upload file. Please try again."));
      } finally {
        setProcessing(false);
      }
    }
  };

  const handleSaveConfigs = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
  
    try {
      const payload = {
        chatbot_config: chatConfig,
        chat_id: chatId,
      };
      console.log(payload);
      const response = await axios.put(`${API_URL}/chatbot/${chatId}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      if (response.status === 200) {
        alert(t("Chatbot configuration saved successfully."));
      } else {
        console.error("Failed to save chatbot configuration:", response);
        alert(t("Failed to save chatbot configuration. Please try again."));
      }
    } catch (error) {
      console.error("Error saving chatbot configuration:", error);
      alert(t("Failed to save chatbot configuration. Please try again."));
    }
  
    setIsChatOpen(false);
    setIsSDKOpen(true);
  };
  console.log(chatConfig);

  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-gray-100" style={{marginTop: "100px"}} dir={language == 'ar' ? 'rtl' : 'ltr'}>
        <div className="container mx-auto py-8">
        <h1 className="text-4xl font-medium text-white mb-6">
            {t("Create Your Chatbot")}
          </h1>
          <p className="text-lg text-default-300 mb-10">
            {t("Upload an Excel file and customize the chatbot appearance.")}
          </p>

          {!showUpload && (
            <button
              className="bg-primary text-white py-2 px-6 rounded-full hover:bg-primary-hover transition-all duration-300"
              onClick={() => {
                window.location.replace("/chatbot");
              }}
            >
              {t("Start New Chatbot")}
            </button>
          )}

          {/* File Upload Section */}
          {showUpload && (
            <div className="max-w-xl mx-auto bg-default-950/40 p-8 rounded-lg backdrop-blur-3xl mb-8">
              <label className="text-lg text-white mb-2 block">{t("Upload Your Data (Excel File)")}</label>
              <p className="text-sm text-white mb-4">{t("Max file size: 10MB")}</p>
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={(e) => setExcelFile(e.target.files[0])}
                className="block w-full p-3 rounded-lg bg-default-700 text-white border border-default-400 mb-4"
                required
                disabled={processing}
              />
              <button className="bg-primary text-white py-2 px-6 rounded-full hover:bg-primary-hover transition-all duration-300" onClick={handleFileUpload}>
                {processing ? t("Processing...") : t("Upload File")}
              </button>
            </div>
          )}

          {/* Configuration and Preview Section */}
          {!showUpload && (
            <div className="flex flex-col lg:flex-row gap-6 bg-gray-200 p-6 rounded-lg shadow-md mt-6">
              {/* Left Section */}
              <div className="flex-1 relative bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold mb-4">{t("Configuration")}</h2>
                <form className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      {t("Chatbot Width (px)")}:
                    </label>
                    <input
                      type="number"
                      value={chatConfig.width}
                      onChange={(e) => updateConfig("width", e.target.value)}
                      className="mt-1 p-2 border border-gray-300 rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      {t("Chatbot Height (px)")}:
                    </label>
                    <input
                      type="number"
                      value={chatConfig.height}
                      onChange={(e) => updateConfig("height", e.target.value)}
                      className="mt-1 p-2 border border-gray-300 rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      {t("Chatbot Background Color")}
                    </label>
                    <input
                      type="color"
                      value={chatConfig.bgColor}
                      onChange={(e) => updateConfig("bgColor", e.target.value)}
                      className="mt-1"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      {t("Header Background Color")}
                    </label>
                    <input
                      type="color"
                      value={chatConfig.headerBgColor}
                      onChange={(e) => updateConfig("headerBgColor", e.target.value)}
                      className="mt-1"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      {t("Header Text Color")}
                    </label>
                    <input
                      type="color"
                      value={chatConfig.headerTextColor}
                      onChange={(e) => updateConfig("headerTextColor", e.target.value)}
                      className="mt-1"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      {t("User Message Background Color")}
                    </label>
                    <input
                      type="color"
                      value={chatConfig.userMsgBgColor}
                      onChange={(e) => updateConfig("userMsgBgColor", e.target.value)}
                      className="mt-1"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      {t("User Message Text Color")}
                    </label>
                    <input
                      type="color"
                      value={chatConfig.userMsgTextColor}
                      onChange={(e) => updateConfig("userMsgTextColor", e.target.value)}
                      className="mt-1"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      {t("Bot Message Background Color")}
                    </label>
                    <input
                      type="color"
                      value={chatConfig.botMsgBgColor}
                      onChange={(e) => updateConfig("botMsgBgColor", e.target.value)}
                      className="mt-1"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      {t("Bot Message Text Color")}
                    </label>
                    <input
                      type="color"
                      value={chatConfig.botMsgTextColor}
                      onChange={(e) => updateConfig("botMsgTextColor", e.target.value)}
                      className="mt-1"
                    />
                  </div>
                  <div>
                    <button onClick={handleSaveConfigs} className="bg-primary text-white py-2 px-6 rounded-full hover:bg-primary-hover transition-all duration-300">
                      {t("Save Configuration & generate Chatbot")}
                    </button>
                  </div>
                </form>
              </div>

              {/* Right Section */}
              <div className="flex-1 bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold mb-4">{t("Live Preview")} (<button onClick={() => setIsChatOpen(!isChatOpen)}>{isChatOpen ? t('hide'): t('show')}</button>)</h2>
                <div
                  className="relative mx-auto"
                  style={{
                    width: `${chatConfig.width}px`,
                    height: `${chatConfig.height}px`,
                    backgroundColor: chatConfig.bgColor,
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "5px",
                    display: isChatOpen ? "flex" : "none",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="flex justify-between items-center p-2"
                    style={{
                      backgroundColor: chatConfig.headerBgColor,
                      color: chatConfig.headerTextColor,
                    }}
                  >
                    <span>{t("Chatbot")}</span>
                    <div>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setMessages([])}
                      >
                        clear
                      </span>
                      {' | '}
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setIsChatOpen(false)}
                      >
                        &#10006;
                      </span>
                    </div>
                  </div>
                  <div
                    className="flex-1 p-2 overflow-y-auto"
                    style={{ borderBottom: "1px solid #ddd" }}
                  >
                    {messages.map((msg, index) => (
                      <div
                        key={index}
                        className={`flex ${
                          msg.type === "user" ? "justify-end" : "justify-start"
                        } mb-2`}
                      >
                        <div
                          className="p-2 rounded"
                          style={{
                            maxWidth: "70%",
                            backgroundColor:
                              msg.type === "user"
                                ? chatConfig.userMsgBgColor
                                : chatConfig.botMsgBgColor,
                            color:
                              msg.type === "user"
                                ? chatConfig.userMsgTextColor
                                : chatConfig.botMsgTextColor,
                          }}
                        >
                          {msg.type === "bot" ? (
                            <ReactMarkdown>{msg.content}</ReactMarkdown>
                          ) : (
                            msg.content
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  {sendingMessage && (
                    <div className="flex justify-start mb-2">
                      <div
                        className="p-2 rounded bg-gray-200 text-gray-600"
                        style={{ maxWidth: "70%" }}
                      >
                        {t("Bot is typing...")}
                      </div>
                    </div>
                  )}
                  <div className="flex border-t border-gray-300">
                    <input
                      type="text"
                      value={userMessage}
                      onChange={(e) => setUserMessage(e.target.value)}
                      className="flex-1 p-2 border-none focus:outline-none"
                      placeholder={t("Type your message...")}
                    />
                    <button
                      onClick={sendMessage}
                      className="p-2 text-white hover:bg-blue-700"
                      style={{backgroundColor: chatConfig.headerBgColor}}
                      disabled={sendingMessage}
                    >
                      {sendingMessage ? t("Sending...") : t("Send")}
                    </button>
                  </div>
                </div>
                <h2 style={{marginTop: 40}} className="text-2xl font-semibold mb-4">SDK Integration Code (<button onClick={() => setIsSDKOpen(!isSDKOpen)}>{isSDKOpen ? t('hide'): t('show')}</button>)</h2>
                {isSDKOpen && <div>
                  <div className="bg-gray-100 p-4 rounded-lg">
                    <pre style={{
                      maxWidth: "500px",
                      wordWrap: "break-word",
                      overflowX: "auto",
                    }} className="whitespace-pre-wrap bg-gray-200 p-4 rounded-lg overflow-auto">
                      <code>
                        {`<script src="${location.protocol}//${location.host}/assets/js/ragminds/ragminds.min.js"></script>
<script>
  Chatbot.initializeChatbot({
    chatId: "${chatId}",
    clientSecret: "${chatbotSecret}",
    configs: ${JSON.stringify(chatConfig, null, 2)},
  });
</script>`}
                      </code>
                    </pre>
                  </div>
                </div>}

              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RagChatmind;
