import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import { useTranslation } from "react-i18next";

import '../assets/css/style.min.css';
import '../assets/css/icons.min.css';
import '../assets/libs/aos/aos.css';

function Home() {
    const { t } = useTranslation();
    const language = localStorage.getItem("i18nextLng");
    useEffect(() => {
        const loadScripts = () => {
          const scripts = [
            "/assets/libs/preline/preline.js",
            "/assets/libs/gumshoejs/gumshoe.polyfills.min.js",
            "/assets/libs/lucide/umd/lucide.min.js",
            "/assets/libs/aos/aos.js",
            "/assets/js/theme.js",
          ];
    
          scripts.forEach((src) => {
            const script = document.createElement("script");
            script.src = src;
            script.async = false; // Load in order
            document.body.appendChild(script);
    
            script.onload = () => {
              console.log(`${src} loaded`);
            };
    
            script.onerror = () => {
              console.error(`Failed to load ${src}`);
            };
          });
        };
    
        // Load scripts after the DOM is fully loaded
        if (document.readyState === "complete") {
          loadScripts();
        } else {
          window.addEventListener("load", loadScripts);
        }
    
        // Cleanup function
        return () => {
          window.removeEventListener("load", loadScripts);
        };
      }, []);
    return (
        <div>
            <Navbar />
            <section dir={language == 'ar'? 'rtl' : 'ltr'} id="home" className="relative overflow-hidden pt-40 pb-20" data-aos="zoom-out" data-aos-easing="ease" data-aos-duration="1000">
                <div className="absolute h-14 w-14 bg-primary/10 top-1/2 start-80 -z-1 rounded-2xl rounded-tl-none rounded-br-none animate-[spin_10s_linear_infinite]"></div>
                <div className="absolute h-14 w-14 bg-primary/20 top-1/2 end-80 -z-1 rounded-full animate-ping"></div>
                <div className="container">
                    <div className="text-center">
                        <div className="flex justify-center mt-6">
                            <div className="max-w-2xl">
                                <h1 className="text-5xl/tight text-default-100 font-medium mb-6">{t("AI Solutions Tailored for Your Business and Personal Needs")}</h1>
                                <p className="text-base text-default-300 font-medium lg:max-w-md mx-auto">{t("Empower your business and daily personal tasks with our LLM and Computer Vision tools.")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section dir={language == 'ar'? 'rtl' : 'ltr'} id="tools" className="py-20" data-aos="zoom-in" data-aos-easing="ease" data-aos-duration="1000">
                <div className="container">
                    <div className="flex items-end justify-between mb-10">
                        <div className="max-w-2xl mx-auto text-center">
                            <h2 className="text-3xl font-medium capitalize text-white mb-4">{t("Our AI Tools")}</h2>
                            <p className="text-sm text-default-200 font-medium">{t("explore each product’s capabilities.")}</p>
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-6">
                        <div className="bg-default-950/40 rounded-xl backdrop-blur-3xl">
                            <div className="p-6">
                                <div className="flex items-center gap-4 mb-4">
                                    <div className="inline-flex items-center justify-center h-10 w-10 bg-red-500/20 text-red-500 rounded-lg">
                                        <i data-lucide="message-circle" className="h-6 w-6"></i>
                                    </div>
                                    <h3 className="text-xl font-medium text-default-200">{t("AI Chatbot")}</h3>
                                </div>

                                <p className="text-sm text-default-100 mb-4">{t("An AI-powered chatbot that provides accurate responses from your internal data. Integrate it seamlessly into your platform.")}</p>

                                <a href="/chatbot" className="inline-flex gap-2 items-center relative text-primary group">
                                    <span className="absolute h-px w-7/12 group-hover:w-full transition-all duration-500 rounded bg-primary/80 -bottom-0"></span>
                                    {t("Select & try")} <i data-lucide="move-right" className="h-4 w-4"></i>
                                </a>
                            </div>
                        </div>
                        <div className="bg-default-950/40 rounded-xl backdrop-blur-3xl">
                            <div className="p-6">
                                <div className="flex items-center gap-4 mb-4">
                                    <div className="inline-flex items-center justify-center h-10 w-10 bg-default-500/20 text-default-500 rounded-lg">
                                        <i data-lucide="file-text" className="h-6 w-6"></i>
                                    </div>
                                    <h3 className="text-xl font-medium text-default-200">{t("Chat with Your PDF")}</h3>
                                </div>

                                <p className="text-sm text-default-100 mb-4">{t("Upload PDFs and engage with the content using our AI-powered PDF-based chatbot.")}</p>

                                <a href="/chatpdf" className="inline-flex gap-2 items-center relative text-primary group">
                                    <span className="absolute h-px w-7/12 group-hover:w-full transition-all duration-500 rounded bg-primary/80 -bottom-0"></span>
                                    {t("Select & try")} <i data-lucide="move-right" className="h-4 w-4"></i>
                                </a>
                            </div>
                        </div>
                        <div className="bg-default-950/40 rounded-xl backdrop-blur-3xl">
                            <div className="p-6">
                                <div className="flex items-center gap-4 mb-4">
                                    <div className="inline-flex items-center justify-center h-10 w-10 bg-sky-500/20 text-sky-500 rounded-lg">
                                        <i data-lucide="camera" className="h-6 w-6"></i>
                                    </div>
                                    <h3 className="text-xl font-medium text-default-200">{t("Face Analyses, Recognition & Identification")}</h3>
                                </div>

                                <p className="text-sm text-default-100 mb-4">{t("Analyse face of the picture or camera with the capability to identify the person of the face")}</p>

                                {/* <a href="#" disabled className="inline-flex gap-2 items-center relative text-primary group"> */}
                                    <span className="absolute h-px w-7/12 group-hover:w-full transition-all duration-500 rounded bg-primary/80 -bottom-0"></span>
                                    {/*t("Select & try")*/} 
                                    {t("Coming soon")}
                                    {/* <i data-lucide="move-right" className="h-4 w-4"></i> */}
                                {/* </a> */}
                            </div>
                        </div>
                        <div className="bg-default-950/40 rounded-xl backdrop-blur-3xl">
                            <div className="p-6">
                                <div className="flex items-center gap-4 mb-4">
                                    <div className="inline-flex items-center justify-center h-10 w-10 bg-sky-500/20 text-sky-500 rounded-lg">
                                        <i data-lucide="headphones" className="h-6 w-6"></i>
                                    </div>
                                    <h3 className="text-xl font-medium text-default-200">{t("Live Translation")}</h3>
                                </div>

                                <p className="text-sm text-default-100 mb-4">{t("Bridge language gaps with real-time, accurate speech translation for seamless communication.")}</p>

                                {/* <a href="#" disabled className="inline-flex gap-2 items-center relative text-primary group"> */}
                                    <span className="absolute h-px w-7/12 group-hover:w-full transition-all duration-500 rounded bg-primary/80 -bottom-0"></span>
                                    {/*t("Select & try")*/} 
                                    {t("Coming soon")}
                                    {/* <i data-lucide="move-right" className="h-4 w-4"></i> */}
                                {/* </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section dir={language == 'ar'? 'rtl' : 'ltr'} id="features" className="py-20">
                <div className="container">
                    <div className="flex items-end justify-between mb-10">
                        <div className="max-w-2xl mx-auto text-center">
                            <h2 className="text-3xl font-medium capitalize text-white mb-4">{t("Advanced Features")}</h2>
                            <p className="text-sm text-default-200 font-medium">{t("Discover the powerful features that make our products ideal for businesses of all sizes.")}</p>
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-3 gap-6 mt-10">
                    <div className="bg-default-950/40 rounded-xl p-10 backdrop-blur-3xl">
                        <h3 className="text-xl font-medium text-white mb-4">{t("Customization")}</h3>
                        <p className="text-sm text-default-100">{t("Tailor our AI solutions to match your brand and meet your specific business needs.")}</p>
                    </div>
                    <div className="bg-default-950/40 rounded-xl p-10 backdrop-blur-3xl">
                        <h3 className="text-xl font-medium text-white mb-4">{t("Real-Time Data")}</h3>
                        <p className="text-sm text-default-100">{t("AI solutions powered by real-time data analysis and insights.")}</p>
                    </div>
                    <div className="bg-default-950/40 rounded-xl p-10 backdrop-blur-3xl">
                        <h3 className="text-xl font-medium text-white mb-4">{t("Easy Integration")}</h3>
                        <p className="text-sm text-default-100">{t("Integrate easily with your existing systems without complex setup or delays.")}</p>
                    </div>
                </div>
                </div>
            </section>

            <section dir={language == 'ar'? 'rtl' : 'ltr'} id="contact" className="py-20">
                <div className="container text-center">
                    <h2 className="text-3xl font-medium capitalize text-white mb-4">{t("Contact Us")}</h2>
                    <p className="text-sm text-default-200 font-medium mb-10">{t("We’re here to answer your questions. Feel free to reach out to us!")}</p>
                    
                    <div className="max-w-2xl mx-auto text-center bg-default-950/40 backdrop-blur-3xl rounded-xl p-6">
                        {/* <h3 className="text-xl font-medium text-default-200 mb-4">{t("Get in Touch")}</h3> */}
                        <ul className="space-y-4">
                            <li className="text-default-300"><strong>Mobile (Whatsapp):</strong> <a target="_blank" href="https://wa.me/+905050102251">+90 505 010 22 51</a></li>
                            <li className="text-default-300"><strong>Email:</strong> info@ragminds.com</li>
                            <li className="text-default-300"><strong>Address:</strong> Istanbul / Turkey</li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;