import React from "react";
import { useTranslation } from "react-i18next";

function Footer() {
    const { t } = useTranslation();
    return (
        <footer>
            <p>© {new Date().getFullYear()} {t("RAGMinds")} - {t("AI Solutions by Ragminds")}</p>
        </footer>
    );
}

export default Footer;
