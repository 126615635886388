import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { API_URL } from "../configs";

export const isTokenValid = (token) => {
  if (!token) return false;

  try {
    const decoded = jwtDecode(token);
    if (!decoded.exp) return true;
    const currentTime = Date.now() / 1000; // Current time in seconds
    return decoded.exp > currentTime; // Check if token is expired
  } catch (error) {
    console.error("Invalid token:", error);
    return false;
  }
};

export const validateTokenWithBackend = async (token) => {
  try {
    const response = await axios.post(
      `${API_URL}/validate-token`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.status === 200;
  } catch (error) {
    console.error("Token validation failed:", error);
    return false;
  }
};
