import React, { createContext, useState, useEffect } from "react";
import { isTokenValid, validateTokenWithBackend } from "../utils/auth";
import { Navigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (isTokenValid(token)) {
      validateTokenWithBackend(token).then((isValid) => {
        if (!isValid) {
          localStorage.removeItem("token");
        }
        setIsAuthenticated(isValid);
      })
      .catch((error) => {
        console.error("Token validation failed:", error);
        localStorage.removeItem("token");
        setIsAuthenticated(false);
      });
    } else {
        localStorage.removeItem("token");
      setIsAuthenticated(false);
    }
  }, []); // Run on app load

  return (
    <AuthContext.Provider value={{ isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
