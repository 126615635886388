import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import translationENG from "./locales/en/translation.json"
import translationTR from "./locales/tr/translation.json"
import translationAR from "./locales/ar/translation.json"
console.log("Default browser language:", localStorage.getItem("language") || window.navigator.language.split("-")[0] || "en");


// the translations
const resources = {
  ar: {
    translation: translationAR,
  },
  en: {
    translation: translationENG,
  },
  tr: {
    translation: translationTR,
  }
}

const language = localStorage.getItem("language") || window.navigator.language.split("-")[0] || "ar"
if (!language) {
  localStorage.setItem("language", "ar")
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("language") || window.navigator.language.split("-")[0] || "en",
    fallbackLng: "ar", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
